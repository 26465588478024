import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Typist from 'react-typist'
import { Layout, Link } from "../components"
import useSiteMetadata from "../hooks/use-site-metadata"
import GithubIcon from "../images/github.svg"
import MailIcon from "../images/mail.svg"
import StackOverflowIcon from "../images/stackoverflow.svg"
import LinkedInIcon from "../images/linkedin.svg"
import './styles.scss'

const IndexPage = () => {
  const { title, subtitle } = useSiteMetadata()
  return (
    <Layout>
      <main>
        <div>
          <Typist className="inline" cursor={{ hideWhenDone: true, hideWhenDoneDelay: 300, }}>
            <h1 >Corey makes&nbsp;</h1>
            <h1>Rice</h1>
            <Typist.Backspace count={4} delay={250} />
            <h1>Web Apps</h1>
            <Typist.Backspace count={8} delay={250} />
            <h1>Mobile Apps</h1>
            <Typist.Backspace count={11} delay={250} />
            <h1>dApps</h1>
            <Typist.Backspace count={12} delay={250} />
            <h1>&nbsp;Rice</h1>
          </Typist>

          <p className="subtitle">{subtitle}</p>
          <Link to={'mailto:hello@coreymakesrice.com'} target="_blank" title={"email"} rel="noreferrer">
            <MailIcon className="icon svg-color-mode" />
          </Link>
          <Link to={'https://github.com/coreyar'} target="_blank" title={"Github"} rel="noreferrer">
            <GithubIcon className="icon svg-color-mode" />
          </Link>
          <Link to={'https://stackoverflow.com/users/3648436/corey'} target="_blank" title={"Stackoverflow"} rel="noreferrer">
            <StackOverflowIcon className="icon svg-color-mode" />
          </Link>
          <Link to={'https://linkedin.com/in/coreyarice'} target="_blank" title={"LinkedIn"} rel="noreferrer">
            <LinkedInIcon className="icon svg-color-mode" />
          </Link>
        </div>
      </main>
    </Layout >
  )
}

export default IndexPage
